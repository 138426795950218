import { createActionGroup, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';
import { IAppState } from '../index.state';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import {
  ICode,
  ICodeQuery,
  ISaveCode,
} from '../../../../../admin/src/app/dashboard/set-ups/parameters/code.model';
import { groupBy } from 'lodash-es';
export namespace CodeStore {
  //#region STORE
  type ICCode = ICode & { title: string };
  const delimiter = `~/~`;
  export interface IState extends EntityState<ICCode> {
    subgroups?: string[];
  }

  const adapter: EntityAdapter<ICCode> = createEntityAdapter<ICCode>({
    sortComparer: (a, b) => a.title.localeCompare(b.title),
    selectId: (i) => i.id,
  });

  const initialState: IState = adapter.getInitialState();
  //#endregion

  //#region ACTIONS
  export const actions = createActionGroup({
    source: 'Code API',
    events: {
      create: props<{ items: ISaveCode[] }>(),
      createError: props<{ error: any }>(),
      createSuccess: props<{ result: ICode[] }>(),
      searchCodes: props<{ query: ICodeQuery }>(),
      searchCodesError: props<{ error: any }>(),
      searchCodesSuccess: props<{ result: ICode[] }>(),
      fetchAll: emptyProps(),
      fetchAllError: props<{ error: any }>(),
      fetchAllSuccess: props<{ result: ICode[] }>(),
      fetchAllSubgroups: emptyProps(),
      removeAllSubgroups: emptyProps(),
      fetchAllSubgroupsError: props<{ error: any }>(),
      fetchAllSubgroupsSuccess: props<{ result: string[] }>(),
      // rebuildCache: props<{ group: string }>(),
      // rebuildCacheSuccess: props<{ items: ICode[]  }>(),
      update: props<{ items: ISaveCode[] }>(),
      updateError: props<{ error: any }>(),
      updateSuccess: props<{ result: ICode[] }>(),
      delete: props<{ id: number }>(),
      deleteError: props<{ error: any }>(),
      deleteSuccess: props<{ result: number }>(),
    },
  });
  //#endregion

  //#region SELECTORE
  export namespace selectors {
    const keyer = (...keys: (string | boolean | number | undefined)[]) =>
      keys.filter((x) => x).join(delimiter);

    const select = (state: IAppState) => state.code;

    const all = createSelector(select, adapter.getSelectors().selectAll);

    const groupBySubgroup = createSelector(all, (codes) => codes.groupBy('codeSubGroup'));

    const groupActiveBySubgroup = createSelector(all, (codes) =>
      groupBy(codes, (item) => keyer(item.codeSubGroup, item.active)),
    );
    

    const groupByCategory = createSelector(all, (codes) =>
      groupBy(codes, (item) => keyer(item.codeCat, item.active)),
    );

    const groupByCategory_Subgroup = createSelector(all, (codes) =>
      groupBy(codes, (item) => keyer(item.codeCat, item.codeSubGroup, item.active)),
    );

    const groupByCode_Subgroup = createSelector(all, (codes) =>
      groupBy(codes, (item) => keyer(item.code, item.codeSubGroup, item.active)),
    );

    const groupByGroup_Subgroup = createSelector(all, (codes) =>
      groupBy(codes, (item) => keyer(item.codeGroup, item.codeSubGroup, item.active)),
    );

    export const selectBySubgroup = (subgroup: string) =>
      createSelector(groupActiveBySubgroup, (map) => map[keyer(subgroup, 1)] || []);

    export const selectAllBySubgroup = (subgroup: string) =>
      createSelector(groupBySubgroup, (map) => map[subgroup] || []);

    export const selectByCategory = (category: string) =>
      createSelector(groupByCategory, (map) => map[category] || []);
    export const selectByCategory_Subgroup = (category: string, subgroup: string) =>
      createSelector(groupByCategory_Subgroup, (map) => map[keyer(category, subgroup, 1)] || []);
    export const selectAllByCode_Subgroup = (code: string, subgroup: string) =>
      createSelector(groupByCode_Subgroup, (map) => map[keyer(code, subgroup, 1)] || []);
    export const selectByCode_Subgroup = (code: string, subgroup: string) =>
      createSelector(groupByCode_Subgroup, (map) => map[keyer(code, subgroup, 1)]?.[0]);
    export const selectByGroup_Subgroup = (group: string, subgroup: string) =>
      createSelector(groupByGroup_Subgroup, (map) => map[keyer(group, subgroup, 1)] || []);

    export const selectAllSubgroups = createSelector(select, (state) => state.subgroups || []);
    // export const selectAllSubgroups = createSelector(select, (state) => state.subgroups || []);
  }
  //#endregion

  //#region REDUCER
  export const reducer = createReducer(
    initialState,
    on(actions.searchCodesSuccess, (state, { result: items }) =>
      adapter.setMany(
        items.map((x) => ({ ...x, title: x.codeTitle })),
        state,
      ),
    ),
    on(actions.fetchAllSuccess, (state, { result: items }) =>
      adapter.setAll(
        items.map((x) => ({ ...x, title: x.codeTitle })),
        state,
      ),
    ),
    on(actions.fetchAllSubgroupsSuccess, (state, { result: items }) => ({ ...state, subgroups: items })),
    on(actions.createSuccess, (state, { result: items }) => adapter.removeAll(state)),
    on(
      actions.updateSuccess,
      (state, { result: items }) => adapter.removeAll(state),
      // adapter.setMany(
      //   items.map((x) => ({ ...x, title: x.codeTitle })),
      //   state,
      // ),
    ),
    on(
      actions.deleteSuccess,
      (state, { result: id }) => adapter.removeAll(state),
      //  adapter.removeOne(id, state)
    ),
    on(
      actions.removeAllSubgroups,
      (state) => ({ ...state, subgroups: undefined }),
      //  adapter.removeOne(id, state)
    ),
  );
  //#endregion
}
