import { Injectable } from '@angular/core';
import { ICode, ISaveCode } from './code.model';
import { BaseFacadeService } from '../../../../../../shared/src/lib/facades/base.facade.service';
import { CodeStore } from '../../../../../../shared/src/lib/@ngrx/code/code.reducer';
import { map } from 'rxjs';
import { EVFunctions } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class CodeFacadeService extends BaseFacadeService {
  create(data: ISaveCode[]) {
    return this.ngrxPromise(CodeStore.actions.create({ items: data }), {
      success: CodeStore.actions.createSuccess,
      error: CodeStore.actions.createError,
    });
  }

  delete = (id: number) => {
    return this.ngrxPromise(CodeStore.actions.delete({ id }), {
      success: CodeStore.actions.deleteSuccess,
      error: CodeStore.actions.deleteError,
    });
  };

  fetchAllSubgroups() {
    return this.select(
      this.store.select(CodeStore.selectors.selectAllSubgroups),
      CodeStore.actions.fetchAllSubgroups(),
    );
  }

  fetchByCatAndSubgroup(cat: string, subgroup: string) {
    return this.select(
      this.store.select(CodeStore.selectors.selectByCategory_Subgroup(cat, subgroup)),
      CodeStore.actions.searchCodes({ query: { codeCat: cat, codeSubGroup: subgroup } }),
    );
  }

  fetchByCat(cat: string) {
    return this.select(
      this.store.select(CodeStore.selectors.selectByCategory(cat)),
      CodeStore.actions.searchCodes({ query: { codeCat: cat } }),
    );
  }

  fetchByCodeAndSubgroup(code: string, subgroup: string) {
    return this.select(
      this.store.select(CodeStore.selectors.selectByCode_Subgroup(code, subgroup)),
      CodeStore.actions.searchCodes({ query: { code, codeSubGroup: subgroup } }),
    );
  }

  fetchTitleByCodeAndSubgroup(code: string, subgroup: string) {
    return this.select(
      this.store.select(CodeStore.selectors.selectByCode_Subgroup(code, subgroup)),
      CodeStore.actions.searchCodes({ query: { code, codeSubGroup: subgroup } }),
    ).pipe(map((r) => EVFunctions.strConcatenator2(code, r?.title)));
  }

  fetchTitleByCodeAndSubgroupFactory = (subgroup: string) => (code: string) =>
    this.fetchTitleByCodeAndSubgroup(code, subgroup); 

  fetchBySubgroup=(subgroup: string, includeInactive = false) =>{
    const codeSubGroup=subgroup?.toUpperCase()
    return this.select(
      this.store.select(
        includeInactive
          ? CodeStore.selectors.selectAllBySubgroup(codeSubGroup)
          : CodeStore.selectors.selectBySubgroup(codeSubGroup),
      ),
      CodeStore.actions.searchCodes({ query: { codeSubGroup } }),
    );
  }

 
 
  fetchBySubgroupFactory =
    (subgroup: string, includeInactive = false) =>
    () =>
      this.fetchBySubgroup(subgroup, includeInactive);
}
